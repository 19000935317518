<template>
  <div :class="getButtonClass" @click="handleClicking(clickEventName)">
    <slot>
      <div class="flex items-center">
        <img :src="icon" class="icon" :class="getIconClass" />
        <span class="wrapper-text"> {{ text }} </span>
      </div>
      <!-- <template v-if="eventEnabled && clickable">
        <i
          class="fas fa-chevron-right text-oDark"
          :class="getCheveronClass"
        ></i>
      </template> -->
    </slot>
  </div>
</template>

<script>
export default {
  name: 'OtoFlagButton',
  props: {
    icon: { required: false },
    text: { required: false },
    size: { required: false, default: 'base' },
    color: { required: false, default: 'none' },
    eventEnabled: { required: false, type: Boolean, default: false },
    clickable: { required: false, type: Boolean, default: false },
    clickEventName: { required: false, type: String, default: 'click' },
  },
  computed: {
    getIconClass() {
      let sizes = ['sm', 'base', 'lg']
      let size = sizes.includes(this.size) ? this.size : 'base'
      return 'icon-' + size
    },
    getCheveronClass() {
      let sizes = {
        sm: 'text-xs',
        base: 'text-sm',
        lg: 'text-sm',
      }
      return Object.keys(sizes).includes(this.size)
        ? sizes[this.size]
        : sizes.base
    },
    getButtonClass() {
      let sizes = ['sm', 'base', 'lg']
      let size = sizes.includes(this.size) ? this.size : 'base'

      if (this.clickable === false || this.eventEnabled === false) {
        return `wrapper wrapper-${size}`
      }

      let colors = ['green', 'purple', 'yellow', 'teal']
      let color = colors.includes(this.color) ? this.color : 'green'

      return `wrapper wrapper-${size} clickable clickable-${color}`
    },
  },
  methods: {
    handleClicking() {
      if (this.clickable === true && this.eventEnabled === true) {
        this.$emit(this.clickEventName)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;

  &.wrapper-sm {
    width: 250px;
    font-size: 13px;
    height: 25px;
    padding: 1.5px 7px 1.5px 2.5px;
    border-radius: 15px;
  }
  &.wrapper-base {
    width: 250px;
    height: 42px;
    padding: 5px 15px 5px 7px;
    border-radius: 27px;
  }
  &.wrapper-lg {
    width: 250px;
    height: 42px;
    padding: 5px 15px 5px 7px;
    border-radius: 27px;
  }

  & .wrapper-text {
    padding-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & .icon {
    object-fit: cover;
    overflow: hidden;
  }
  & .icon-sm {
    width: 19px;
    height: 19px;
  }
  & .icon-base {
    width: 32px;
    height: 32px;
  }
  & .icon-lg {
    width: 32px;
    height: 32px;
  }
}

.clickable {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: #f5f3f3;
  }
  &.clickable-green {
    border: solid 1px #06ae26;
    color: #06ae26;
  }
  &.clickable-purple {
    border: solid 1px #7d3cf8;
    color: #7d3cf8;
  }
  &.clickable-yellow {
    border: solid 1px #ffed00;
    color: #ffed00;
  }
  &.clickable-teal {
    border: solid 1px #00c1f3;
    color: #00c1f3;
  }
}
</style>
